import React, {ReactNode, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import rolodex from 'goodeggs-rolodex';
import {useSelector} from 'react-redux';

import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import {PageType} from 'web/helpers/redux_client';
import Alert from 'web/components/alert';
import SignInHelpers from 'web/components/market_layout/components/header/components/signin_helpers';
import {MarketLayoutStore} from 'web/components/market_layout/store_builder';
import assetPath from 'web/helpers/asset_path';

import {getCheckoutSessionUrl} from './helpers/api_client';

interface FAQ {
  title: string;
  description: string | ReactNode;
}

export const faqList: FAQ[] = [
  {
    title: 'What is the Good Eggs Membership?',
    description:
      'Good Eggs members enjoy unlimited deliveries with zero fees, priority customer service, and surprise and delight moments along the way.',
  },
  {
    title: 'How much does it cost to become a member?',
    description: (
      <>
        <p>Good Eggs offers two membership options for you to choose from:</p>
        <ul>
          <li>
            Monthly Membership: Enjoy zero fees, priority customer service, and surprise and delight
            moments for $29 per month – a savings of $50+ per month on average.
          </li>
          <li>
            Annual Membership: Save even more with our annual membership offering and enjoy all the
            benefits year-round for $299 per year.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'When will my benefits start?',
    description: 'Membership benefits start on the day you enroll.',
  },
  {
    title: 'When will I be charged?',
    description:
      'Your credit card will be charged at the time of enrollment. Your membership will automatically renew on the same day each month if you select the monthly option and the same day each year if you select the annual option.',
  },
  {
    title: 'Is there an order minimum?',
    description: 'The order minimum for all orders, regardless of membership status, is $50.',
  },
  {
    title: 'Can I pause my membership?',
    description: (
      <p>
        Members may pause membership for 1 month, 3 months, 6 months, or 12 months. Visit your{' '}
        <a href="/account/membership">Account page</a> and click on ‘Membership’, then ‘Cancel’,
        then ‘Pause’ to pause your membership.
      </p>
    ),
  },
  {
    title: 'Is there a free trial?',
    description:
      'At this time, we don’t offer a free trial, but you have the ability to cancel at any time.',
  },
  {
    title: 'How do I cancel my membership?',
    description: (
      <p>
        Visit your <a href="/account/membership">Account page</a> and click on ‘Membership’, then
        ‘Cancel’, then ‘Cancel’ again to cancel your membership. Your benefits will continue through
        the end of the current billing cycle.
      </p>
    ),
  },
  {
    title: 'Want to get in touch with us?',
    description: (
      <p>
        We want your feedback — reach out to{' '}
        <a href={`mailto:${rolodex.eaterHelpEmailAddress()}`}>{rolodex.eaterHelpEmailAddress()}</a>{' '}
        or text {rolodex.eaterHelpPhoneNumber()} and we’ll get back to you soon as we can.
      </p>
    ),
  },
];

const JoinMembershipPage: PageType<MarketLayoutStore> = () => {
  const {
    error: stateError,
    isMember,
    isLoggedIn,
  } = useSelector((state: MarketLayoutStore) => ({
    error: state.error,
    isMember: state.user?.membership?.isMember,
    isLoggedIn: state.user != null,
  }));
  const [error, setError] = useState<{message: string; heading: string} | null>(stateError);

  const handleSubmit = async (): Promise<void> => {
    if (!isLoggedIn) {
      SignInHelpers.signinAndReturn();
      return;
    }

    if (isMember) {
      window.location.href = '/account/membership';
      return;
    }

    try {
      const stripeUrl = await getCheckoutSessionUrl();
      window.location.href = stripeUrl;
    } catch (apiError) {
      if ((apiError as {type: string; message: string}).type) {
        setError({
          heading: 'Membership Checkout error',
          message: (apiError as {type: string; message: string}).message,
        });
      } else {
        setError({
          heading: 'Membership Checkout error',
          message:
            'Sorry, there was a problem connecting to the Membership Checkout. Try again later!',
        });
      }
    }
  };

  return (
    <MarketLayout disableBasketDropdown>
      <div className="join-membership">
        <Helmet>
          <title>Join Membership | Good Eggs</title>
        </Helmet>

        <main className="join-membership__content">
          <section className="join-membership__description">
            <h1 className="join-membership__title">
              <strong>Become a Good Eggs Member and save on every order</strong>
            </h1>
            <hr className="small-divider" />
          </section>
          <div className="join-membership__body">
            <section className="join-membership__benefits">
              <h1 className="join-membership__title">
                <strong>Why become a member?</strong>
              </h1>

              <p className="join-membership__benefit_description">
                For Good Eggs customers, an annual or monthly membership is a great way to save
                money on every order. In addition, membership entitles you to a list of great perks,
                including:
              </p>

              <h3 className="join-membership__benefit_header">early access</h3>
              <p className="join-membership__benefit_description">
                Get regular first dibs on select items from the best producers and farmers.
              </p>
              <h3 className="join-membership__benefit_header">reduced fees</h3>
              <ul className="join-membership__benefit_description join-membership__benefit_description__list">
                <li>
                  Members enjoy close to 70% savings per order in service and delivery fees with
                  just one flat $5.00 rate versus $15.99 for non-members.
                </li>
                <li>
                  If you’re paying for an annual membership, you make up the cost of membership if
                  you order more than twice per month.
                </li>
                <li>
                  And if you’re a monthly member, you make up the cost of membership if you order
                  more than three times per month.
                </li>
              </ul>
              <h3 className="join-membership__benefit_header">
                a chance to rack up savings four times a year
              </h3>
              <p className="join-membership__benefit_description">
                Once per quarter, we invite you to stock up and save. Get unmatched deals across our
                aisles from local produce to best quality skincare and wellness products and wine to
                pantry staples.
              </p>
              <h3 className="join-membership__benefit_header">priority customer service</h3>
              <p className="join-membership__benefit_description">
                Members receive a dedicated email address and phone number to reach our members-only
                customer service team at any time, and priority-level response time.
              </p>
            </section>
            <div className="membership__row_image">
              <img
                alt="hands carrying a GoodEggs box"
                src={`${assetPath('/img/web/join-membership/background.png')}?auto=format`}
              />
            </div>
          </div>
          <section className="join-membership__join">
            <div className="join-membership__join__content">
              <button
                type="submit"
                className="btn-join-membership"
                onClick={async () => handleSubmit()}
              >
                {!isMember ? 'JOIN MEMBERSHIP' : 'MANAGE MEMBERSHIP'}
              </button>
              {error && (
                <div className="join-membership__error" data-testid="join-membership__error">
                  <Alert type="error" heading={error.heading}>
                    {error.message}
                  </Alert>
                </div>
              )}
            </div>
          </section>

          <footer className="join-membership__footer">
            <a href="/terms/membership">Good Eggs Membership Terms & Conditions</a>
          </footer>
        </main>
      </div>
    </MarketLayout>
  );
};

JoinMembershipPage.pageName = 'Join Membership';
JoinMembershipPage.reducer = (state, action) => {
  if (!state) throw new Error('State should always be preloaded here');

  const newState = marketLayoutReducer(state, action);
  return newState;
};

export default JoinMembershipPage;
